import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import { nationalTaxServiceCredentialsDialogVar } from '@fingo/lib/apollo/reactive-variables/alerts';
import DesktopCredentialsCard from './DesktopCredentialsCard';
import MobileCredentialsCard from './MobileCredentialsCard';

const CredentialsCard = ({ avoidFingoDialog, DialogComponent, ...props }) => {
  const isMobile = useIsMobile();
  const selectedCompany = useSelectedCompany();
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => {
    if (avoidFingoDialog) {
      nationalTaxServiceCredentialsDialogVar({ open: true, autocomplete: !!selectedCompany });
    } else {
      setOpen((prevState) => !prevState);
    }
  }, []);
  const CredentialCardComponent = isMobile ? MobileCredentialsCard : DesktopCredentialsCard;

  return (
    <>
      <CredentialCardComponent {...props} toggle={toggle} />
      {DialogComponent && <DialogComponent open={open} setOpen={setOpen} />}
    </>
  );
};

CredentialsCard.propTypes = {
  avoidFingoDialog: PropTypes.bool,
  DialogComponent: PropTypes.func,
};

CredentialsCard.defaultProps = {
  avoidFingoDialog: false,
  DialogComponent: null,
};

export default CredentialsCard;
