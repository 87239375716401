import React from 'react';
import PropTypes from 'prop-types';
import ProgressiveImageBox from '@fingo/lib/components/boxes/ProgressiveImageBox';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const CredentialProviderAuthMethodSelector = ({
  selectedProvider,
  onCancel,
  onClick,
}) => (
  <Stack spacing={3} alignItems="center" mx={{ sx: 0, md: 8 }}>
    <ProgressiveImageBox src={selectedProvider?.iconUrl} alt={selectedProvider?.name} />
    <Button
      onClick={onCancel}
      variant="text"
      size="small"
      sx={{ textDecoration: 'underline' }}
    >
      ¿No es tu facturador? Selecciona aquí el tuyo
    </Button>
    <Stack direction="row" spacing={1.5}>
      <Button
        variant="hoverHighlightButton"
        color="text.primary"
        onClick={() => onClick('certificate')}
      >
        Ingresa con tu certificado digital
      </Button>
      <Button
        variant="hoverHighlightButton"
        color="text.primary"
        onClick={() => onClick('username')}
      >
        Ingresa con tus credenciales del facturador
      </Button>
    </Stack>
  </Stack>
);

CredentialProviderAuthMethodSelector.propTypes = {
  selectedProvider: PropTypes.shape({
    name: PropTypes.string,
    iconUrl: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

CredentialProviderAuthMethodSelector.defaultProps = {
  selectedProvider: null,
};

export default CredentialProviderAuthMethodSelector;
